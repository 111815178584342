import { h } from "preact";

export default () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 244.285 244.285"
      style="enable-background:new 0 0 244.285 244.285;"
    >
      <g>
        <g>
          <path
            style="fill:#010002;"
            d="M29.317,2.189c-15.752,0-28.566,12.814-28.566,28.566s12.814,28.566,28.566,28.566
                        s28.566-12.814,28.566-28.566S45.068,2.189,29.317,2.189z M29.317,48.443c-9.752,0-17.688-7.936-17.688-17.688
                        s7.936-17.688,17.688-17.688c9.752,0,17.688,7.936,17.688,17.688S39.069,48.443,29.317,48.443z"
          />
          <path
            style="fill:#010002;"
            d="M121.77,2.189c-15.752,0-28.566,12.814-28.566,28.566s12.814,28.566,28.566,28.566
                        s28.566-12.814,28.566-28.566S137.521,2.189,121.77,2.189z M121.77,48.443c-9.752,0-17.688-7.936-17.688-17.688
                        s7.936-17.688,17.688-17.688s17.688,7.936,17.688,17.688S131.522,48.443,121.77,48.443z"
          />
          <path
            style="fill:#010002;"
            d="M215.719,59.321c15.752,0,28.566-12.814,28.566-28.566S231.47,2.189,215.719,2.189
                        s-28.566,12.814-28.566,28.566S199.967,59.321,215.719,59.321z M215.719,13.067c9.752,0,17.688,7.936,17.688,17.688
                        s-7.936,17.688-17.688,17.688c-9.752,0-17.688-7.936-17.688-17.688S205.967,13.067,215.719,13.067z"
          />
          <path
            style="fill:#010002;"
            d="M28.566,150.855c15.752,0,28.566-12.809,28.566-28.566c0-15.752-12.814-28.566-28.566-28.566
                        S0,106.538,0,122.289S12.814,150.855,28.566,150.855z M28.566,104.601c9.752,0,17.688,7.936,17.688,17.688
                        s-7.936,17.688-17.688,17.688s-17.688-7.936-17.688-17.688S18.814,104.601,28.566,104.601z"
          />
          <path
            style="fill:#010002;"
            d="M121.025,150.855c15.752,0,28.566-12.809,28.566-28.566c0-15.752-12.814-28.566-28.566-28.566
                        s-28.566,12.814-28.566,28.566S105.273,150.855,121.025,150.855z M121.025,104.601c9.752,0,17.688,7.936,17.688,17.688
                        s-7.936,17.688-17.688,17.688s-17.688-7.936-17.688-17.688S111.267,104.601,121.025,104.601z"
          />
          <path
            style="fill:#010002;"
            d="M214.974,150.855c15.752,0,28.566-12.809,28.566-28.566c0-15.752-12.814-28.566-28.566-28.566
                        s-28.566,12.814-28.566,28.566C186.402,138.041,199.222,150.855,214.974,150.855z M214.974,104.601
                        c9.752,0,17.688,7.936,17.688,17.688s-7.936,17.688-17.688,17.688c-9.752,0-17.688-7.936-17.688-17.688
                        S205.216,104.601,214.974,104.601z"
          />
          <path
            style="fill:#010002;"
            d="M29.224,184.964c-15.752,0-28.566,12.814-28.566,28.566c0,15.752,12.814,28.566,28.566,28.566
                        S57.79,229.286,57.79,213.53C57.79,197.783,44.976,184.964,29.224,184.964z M29.224,231.217c-9.752,0-17.688-7.936-17.688-17.688
                        c0-9.752,7.936-17.688,17.688-17.688s17.688,7.936,17.688,17.688C46.912,223.282,38.976,231.217,29.224,231.217z"
          />
          <path
            style="fill:#010002;"
            d="M121.683,184.964c-15.752,0-28.566,12.814-28.566,28.566c0,15.752,12.814,28.566,28.566,28.566
                        s28.566-12.809,28.566-28.566C150.249,197.783,137.434,184.964,121.683,184.964z M121.683,231.217
                        c-9.752,0-17.688-7.936-17.688-17.688c0-9.752,7.936-17.688,17.688-17.688s17.688,7.936,17.688,17.688
                        C139.371,223.282,131.435,231.217,121.683,231.217z"
          />
          <path
            style="fill:#010002;"
            d="M215.632,184.964c-15.752,0-28.566,12.814-28.566,28.566c0,15.752,12.814,28.566,28.566,28.566
                        s28.566-12.809,28.566-28.566C244.192,197.783,231.378,184.964,215.632,184.964z M215.632,231.217
                        c-9.752,0-17.688-7.936-17.688-17.688c0-9.752,7.936-17.688,17.688-17.688c9.752,0,17.688,7.936,17.688,17.688
                        C233.32,223.282,225.379,231.217,215.632,231.217z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
